import type React from 'react';

declare const frontendReactComponents:
  | Array<{
      containerId: string;
      componentName: string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      props?: Record<string, any>;
    }>
  | undefined;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type LazyLoadedComponent = () => Promise<React.FC<any>>;

export type FrontendComponentsMapping = Record<string, LazyLoadedComponent>;

export const hydrateReactComponents = async (
  componentsMapping: FrontendComponentsMapping,
) => {
  if (frontendReactComponents && frontendReactComponents.length) {
    try {
      const { createElement, hydrateRoot } = await import('./react-library.js');

      await Promise.all(
        frontendReactComponents.map(
          async ({ componentName, containerId, props }) => {
            const getComponent = componentsMapping[componentName];

            if (!getComponent) {
              return;
            }

            try {
              const Component = await getComponent();
              const rootContainer = document.querySelector(`#${containerId}`);

              if (rootContainer) {
                hydrateRoot(rootContainer, createElement(Component, props));
              } else {
                console.error(
                  `Missing react root (#${containerId}) for component: ${componentName}`,
                );
              }
            } catch (error) {
              console.error(
                `Error when loading component ${componentName}`,
                error,
              );
            }
          },
        ),
      );
    } catch (error) {
      console.error('Error when hydrating react components', error);
    }
  }
};
